import React from "react";
import { Offline, Online } from "react-detect-offline";
import { Redirect, Route } from "react-router-dom";
import InternetOffline from "../components/common/InternetOffline";
import NavSection from "../components/common/Layouts/NavSection";
import { getToken } from "../utils";

const Auth = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getToken() ? (
        <div className="bg-lightWhite min-h-screen">
          <NavSection {...props} baseRoute={rest.baseRoute} />

          <Offline>
            <InternetOffline />
          </Offline>
          <Online>
            <Component {...props} />
          </Online>

          {/* <GAComponent Component={Component} {...props} /> */}
        </div>
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export default Auth;
